@import '../../../node_modules/antd/lib/style/themes/default.less';
@import "_light-theme.less";
@import "../../../node_modules/antd/dist/antd.less";
@import '../../../node_modules/@react-pdf-viewer/core/lib/styles/index.css';
@import '../../../node_modules/@react-pdf-viewer/toolbar/lib/styles/index.css';
@import "../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

@import "features/_features.less";

@media (max-width: 767px) {
  #--re-frame-10x-- {
    display: none;
  }
}


.anticon {
  font-size: @font-size-lg;
}

.react-tel-input .flag-dropdown {
  background-color: white;
}

.iti {
  width: 100%;
}

.iti__flag {
  background-image: url("../../../node_modules/intl-tel-input/build/img/flags.png");

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    & {
      background-image: url("../../../node_modules/intl-tel-input/build/img/flags@2x.png");
    }
  }
}



// --------------------------------------------------------
// Customize Theme
//@font-face {
//  font-family: 'Proxima Soft';
//  src: url("../fonts/ProximaSoft-Black.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-BlackIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-Bold.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-BoldIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-ExtraBold.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-ExtraBoldIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-Light.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-LightIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-Medium.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-MediumIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-Regular.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-RegularIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-SemiBold.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-SemiBoldIt.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-Thin.ttf") format("truetype"),
//  url("../fonts/ProximaSoft-ThinIt.ttf") format("truetype");
//  font-weight: normal;
//}

@border-radius-base: 5px;
@font-family: 'Proxima Soft', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@gray-base: #737373;
@gray-1: color(~`colorPalette('@{gray-6}', 1) `);
@gray-2: color(~`colorPalette('@{gray-6}', 2) `);
@gray-3: color(~`colorPalette('@{gray-6}', 3) `);
@gray-4: color(~`colorPalette('@{gray-6}', 4) `);
@gray-5: color(~`colorPalette('@{gray-6}', 5) `);
@gray-6: @gray-base;
@gray-7: color(~`colorPalette('@{gray-6}', 7) `);
@gray-8: color(~`colorPalette('@{gray-6}', 8) `);
@gray-9: color(~`colorPalette('@{gray-6}', 9) `);
@gray-10: color(~`colorPalette('@{gray-6}', 10) `);

// --------------------------------------------------------
// Custom Styles

.ant-layout-sider-zero-width-trigger {
  top: 8px;
  opacity: 0.9;
}

.main-page-layout {
  height: 100vh;

  &__title {
    margin: @margin-lg;
  }

  &__content {
    padding: @padding-lg;
  }

  &__header {
    background-color: @white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px 0;
  }
}

.sider-page-layout {
  height: 100%;
  min-height: 100vh;

  &__title {
    margin: @margin-lg;
  }

  &__header {
    background-color: @white;
    display: flex;
    align-items: center;
    padding: @padding-sm;

    @media (min-width: @screen-md-min) {
      padding: @padding-lg;
    }
  }

  &__school-logo {
    height: 60px;
  }

  &__school-name {
    display: none;

    @media (min-width: @screen-md-min) {
      display: inline;
      font-weight: 600;
      margin-left: 16px;
    }
  }

  &__main-menu {
    border-bottom: none;
  }

  &__content {
    padding: @padding-md;
    margin: @margin-md;
    background-color: @white;

    @media (min-width: @screen-md-min) {
      padding: @padding-lg;
      margin: @margin-lg;
    }
  }
}

.user-avatar-dropdown {
  display: flex;
  cursor: pointer;
  justify-content: flex-end;

  &__text {
    display: none;
  }

  @media (min-width: @screen-md-min) {
    &__text {
      display: inline;
    }
  }
}

.logo {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;

  &__img {
    width: 128px;
    height: 48px;
    margin: auto;
    background-image: url("../img/klei-logo-light.svg");
    background-size:     96px 64px;
    background-repeat:   no-repeat;
    background-position: center center;
  }
}

.login-content {
  //display: flex;
  //width: 100%;
  text-align: center;
}

.logout-link {
  color: @red-9;
}

.section-heading.ant-typography {
  margin-bottom: 24px;
}


.book-card {
  @book-card-width: 140px;

  width: @book-card-width;
  box-shadow: @shadow-2;

  & .ant-card-cover > img {
    width: @book-card-width;
    height: 150px;
  }

  & .ant-card-body {
    padding: @padding-sm;
  }

  & .ant-card-meta-title {
    font-size: @font-size-sm;
  }

  @media (min-width: @screen-md-min) {
    @book-card-width: 220px;
    width: @book-card-width;

    & .ant-card-cover > img {
      width: @book-card-width;
      height: 310px;
    }

    & .ant-card-body {
      padding: @padding-md;
    }

    & .ant-card-meta-title {
      font-size: @font-size-base;
    }
  }
}

#pdf-viewer-root {
  // TODO how to make the pdf-viewer-root occupy the pdf-viewer
  // during inspect element.

  .pdf-viewer {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10;
    left: 0;
    top: 0;
    padding: 0 16px 0;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */

    @controls-height: 40px;

    &__toolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 8px;
      gap: 8px;
    }

    // override the react-pdf-viewer black font-color
    & .rpv-core__minimal-button {
      color: #fff;
    }

    &__close-button {
      &:hover {
        color: white;
        border-color: white;
        border-width: 2px;
        font-weight: bold;
      }
    }
  }
}

.section-card {
  color: white;
  box-shadow: @box-shadow-base;
  //padding: @padding-sm;
  //width: 350px;
  height: 150px;
  font-size: 24px;
  font-weight: 700;
  //border: 2px solid @gray-1;
  background: @purple-7;
}

.user-avatar-dropdown {
  margin-left: auto;
  color: @text-color;

  .ant-avatar-image {
    width: 44px;
    height: 44px;
  }
}

.avatar {
  //width: 150px;
  //height: 150px;
  //background-image: url("../img/klei.jpg");
  //border-radius: 50%;
  //
  background-size: cover;
  background-position: top center;
  color: @primary-color;
  position: relative;
  cursor: pointer;


  & .ant-avatar-image {
    box-shadow: 0 0 0 1px #ddd;

    & > .ant-image {
      margin: 0 auto;
      width: 100%;
    }
  }

  &__edit-button {
    position: absolute;
    bottom: 0;
    left: 16px;
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4px;
    border: 1px solid @gray-2;
  }
}

.student-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: @screen-sm-min) {
    flex-direction: row;
  }
}

.student-info {
  display: flex;
  flex-direction: column;

  &__field {
    display: flex;
    margin-bottom: @margin-sm;
  }

  &__label {
    margin-right: @margin-xs;

    &:after {
      content: ":"
    }
  }

  &__text {
    color: @gray-6;
  }

  & > p {
    margin-bottom: 4px;
  }

  &__extra {
    margin-top: @margin-md;
  }
}

.week-schedule {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 8px 0;
    flex-wrap: wrap;
  }

  &__day {
    width: 100%;
    font-weight: 500;
    margin-bottom: @margin-xs;

    @media (min-width: @screen-md-min) {
      width: 200px;
    }
  }

  &__schedule-list {
    flex: 1;
  }

  &__schedule {
    margin-bottom: @margin-sm;
    display: flex;
    gap: @padding-md;

    @media (min-width: @screen-md-min) {
      gap: @padding-lg;
      margin-bottom: @margin-xs;
    }
  }

  &__time-slot {
    min-width: 100px;
  }
}

.login-page {
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;

  @media (min-width: @screen-md-min) {
    background-color: @layout-body-background;
  }

  &__content {
    width: 100%;
    background-color: white;
    margin-top: 48px;
    padding: 48px 56px;

    @media (min-width: @screen-md-min) {
      padding: 48px 84px 84px 84px;
      border-radius: 24px;
      width: 524px;
      margin-top: 48px;
    }
  }

  &__logo {
    display: flex;
    margin-bottom: 56px;
  }
  &__logo img {
    width: 128px;
    height: 64px;
    margin: auto;
  }

  &__copy {
    text-align: center;
  }
}

.supporting-documents {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
  }
}
